import React, { Component } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';
import emailjs from '@emailjs/browser';

import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { AttestationDeSuiviEtDeFinDeFormation } from '../PDFs/AttestationDeSuiviEtDeFinDeFormation';
import { AttestationDeSuiviEtDeFormation } from '../PDFs/AttestationDeSuiviDeFormation';
import { CertificatDInscription } from '../PDFs/CertificatDInscription';
export class ProfilEtudiantInscrit extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    // createNotification = () => {
    //     return NotificationManager.success('Success message', "L'email a bien été envoyé!");
    // }

    //notify = () => toast("L'email a été envoyé avec succès!");

    // handleComplianceEmail() {
    //     // emailjs.init({
    //     //     publicKey: ,
    //     // });

    // }




    handleSubmit(event) {
        event.preventDefault();
        // fetch(process.env.REACT_APP_API + 'posts', {
        //     method: 'PUT',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         PostId: event.target.PostId.value,
        //         PostName: event.target.PostName.value,
        //         Category: event.target.Category.value,
        //         PostDescription: event.target.PostDescription.value,
        //         PostYoutubeHref: event.target.PostYoutubeHref.value,
        //         AdsTitle: event.target.AdsTitle.value,
        //         AdsImageFileName: this.photofilename,
        //         AdsLink: event.target.AdsLink.value
        //     })
        // })
        //     .then(response =>
        //         response.json()
        //     )
        //     .then((result) => {
        //         alert(result);
        //     })
        //     .catch(error => {
        //         console.error(error.message)
        //     });
        // emailjs.init({
        //     publicKey: process.env.REACT_APP_EMAILJS_PUBLICKEY,
        // });
        // try {

        //     emailjs.send("service_2dzzalg", "validation_candidature", {
        //         from_name: "ISMI",
        //         to_name: `${this.props.prenom} ${this.props.nom}`,
        //         recipient: `${this.props.email}`
        //     });

        //     this.createNotification();
        // }
        // catch (e) {
        //     console.log(e)
        // }

    }

    render() {

        return (
            <div className="container">
                <Modal
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header >
                        <Modal.Title id="contained-modal-title-vcenter">
                            Télécharger attestation de suivi et de fin de formation ou certificat d'inscription
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <Form onSubmit={this.handleSubmit}>
                                    {this.props ?

                                        (
                                            <div>

                                                <Form.Group controlId="ClientId">
                                                    <Form.Label>ID du candidat</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="ClientId"
                                                        required
                                                        disabled
                                                        defaultValue={this.props.clientid}
                                                    />

                                                </Form.Group>
                                                <Form.Group controlId="Nom">
                                                    <Form.Label>Nom</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="nom"
                                                        required
                                                        disabled
                                                        defaultValue={this.props.nom}
                                                    />

                                                </Form.Group>
                                                <Form.Group controlId="Prenom">
                                                    <Form.Label>Prénom</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="prenom"
                                                        required
                                                        disabled
                                                        defaultValue={this.props.prenom}
                                                    />

                                                </Form.Group>
                                                <Form.Group controlId="Email">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="email"
                                                        required
                                                        defaultValue={this.props.email}
                                                    />

                                                </Form.Group>
                                                <Form.Group controlId="Numero">
                                                    <Form.Label>Numéro de téléphone</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="numero"
                                                        required
                                                        disabled

                                                        defaultValue={"+" + this.props.numero}
                                                    />

                                                </Form.Group>

                                                <Form.Group controlId="Numero">
                                                    <Form.Label>Date de début de formation</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="numero"
                                                        required

                                                        disabled
                                                        defaultValue={this.props.dateDebutDeFormation}
                                                    />

                                                </Form.Group>
                                                <Form.Group controlId="DateFinDeFormation">
                                                    <Form.Label>Date de fin de formation</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="dateFinDeFormation"
                                                        required
                                                        disabled
                                                        defaultValue={this.props.dateFinDeFormation}
                                                    />

                                                </Form.Group>
                                                <Form.Group controlId="DateDeNaissance">
                                                    <Form.Label>Date de Naissance</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="dateDeNaissance"
                                                        required
                                                        disabled
                                                        defaultValue={this.props.dateDeNaissance}
                                                    />

                                                </Form.Group>

                                                <Form.Group controlId="nomDeLaFormation">
                                                    <Form.Label>Intitulé de la formation</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="nomDeLaFormation"
                                                        required
                                                        disabled
                                                        defaultValue={this.props.nomDeLaFormation}
                                                    />

                                                </Form.Group>
                                            </div>
                                        ) : 'null'
                                    }
                                    {/* <Form.Group controlId="Questionnaire">
                                        <h2>Réponses questionnaire</h2>
                                        {this.props.checkboxesQuestionnaire ?

                                            //  this.props.checkboxesQuestionnaire.map(

                                            (<div>

                                                <h3>1. Par quel(s) type(s) de formation(s) êtes-vous intéressé (e)?</h3>
                                                <p>{this.props.checkboxesQuestionnaire.filter(item => item.includes("1.1")).map(filtered => <p>Niveau de formation souhaité: {filtered}</p>)}</p>
                                                <p>{this.props.checkboxesQuestionnaire.filter(item => item.includes("1.2")).map(filtered => <p>Domaine de formation: {filtered}</p>)}</p>
                                                <p>{this.props.checkboxesQuestionnaire.filter(item => item.includes("1.3")).map(filtered => <p>Mode de formation souhaité: {filtered}</p>)}</p>
                                                <h3>2. Quel est votre parcours académique et professionnel ?</h3>
                                                <p>{this.props.checkboxesQuestionnaire.filter(item => item.includes("2.1")).map(filtered => <p>Dernier diplôme: {filtered}</p>)}</p>
                                                <p>Spécialité dernier diplôme: {this.props.specialiteDiplome}</p>
                                                <p>{this.props.checkboxesQuestionnaire.filter(item => item.includes("2.3")).map(filtered => <p>Capable de présenter diplôme: {filtered}</p>)}</p>
                                                <p>Non, pourquoi: {this.props.nonPresenterDiplomePourquoi}</p>
                                                <p>{this.props.checkboxesQuestionnaire.filter(item => item.includes("2.5")).map(filtered => <p>Années expérience profesionnelle: {filtered}</p>)}</p>
                                                <h3>3. Quel est votre objectif en suivant cette formation?</h3>
                                                <p>{this.props.checkboxesQuestionnaire.filter(item => item.includes("3.1")).map(filtered => <p>Pourquoi cette formation:    {filtered}</p>)}</p>
                                                <p>Autre objectif de formation: {this.props.autreObjectif}</p>
                                                <h3>4. Quelles sont vos attentes et motivations pour cette formation?</h3>
                                                <p>Attente de la formation: {this.props.attenteDeLaFormation}</p>
                                                <p>Motivation: {this.props.motivation} </p>
                                                <p>Depuis combien de temps intéressé par la formation: {this.props.depuisCombienDeTempsInteressesParLaFormation} </p>
                                                <p>{this.props.checkboxesQuestionnaire.filter(item => item.includes("4.d")).map(filtered => <p>Combien de temps à consacrer: {filtered}</p>)}</p>

                                                <h3>4 bis. Uniquement pour les candidats souhaitant un contrat en apprentissage</h3>
                                                <p>Coordonnées référent alternance: {this.props.coordonneesReferentAlternance} </p>
                                                <p>{this.props.checkboxesQuestionnaire.filter(item => item.includes("4.bis.c")).map(filtered => <p>Poste correspond-t-il a votre formation: {filtered}</p>)}</p>
                                                <p>Qualité être un bon profesionnel: {this.props.qualitesPourEtreUnBonProfessionnel} </p>
                                                <h3>5. Avez-vous des besoins spécifiques, par exemple, liés à une situation de handicap?</h3>
                                                <p>{this.props.besoinsSpecifiquesHandicap}</p>
                                                <h3>6. Observations complémentaires</h3>
                                                <p>{this.props.checkboxesQuestionnaire.filter(item => item.includes("6.a")).map(filtered => <p>compréhension formation demande 600 à 1100h: {filtered}</p>)}</p>
                                                <p>{this.props.checkboxesQuestionnaire.filter(item => item.includes("6.b")).map(filtered => <p>Supports numériques: {filtered}</p>)}</p>
                                                <p>Besoins pour la création d'activité: {this.props.besoinsCreationActivite}</p>
                                                <p>Informations complémentaires: {this.props.autresInformationsComplementaires}</p>

                                            </div>)

                                            //console.log(this.props.checkboxesQuestionnaire)

                                            : "Null"}

                                    </Form.Group> */}
                                    <Form.Group controlId="Documents">
                                        <h2>Documents </h2>
                                        {/* <Form.Control
                                            type="text"
                                            name="ImageFileName"
                                            required
                                            defaultValue={this.props.postimagefilename}
                                        /> */}
                                        <div style={{ margin: "20px" }}>


                                            <ol>
                                                <li>

                                                    <a href={process.env.REACT_APP_API_URL + this.props.cniMedia}>CV</a><br />
                                                </li>
                                                <li>

                                                    <a href={process.env.REACT_APP_API_URL + this.props.diplomeMedia}>Diplôme</a><br />
                                                </li>
                                                <li>

                                                    <a href={process.env.REACT_APP_API_URL + this.props.lettreDeMotivationMedia}>Lettre de motivation</a><br />
                                                </li>
                                                <li>

                                                    <a href={process.env.REACT_APP_API_URL + this.props.cniMedia}>Pièce d'identié</a><br />
                                                </li>
                                                {process.env.REACT_APP_API_URL + this.props.cerfaMedia ?
                                                    <li>

                                                        <a href={process.env.REACT_APP_API_URL + this.props.cerfaMedia}>Cerfa signé pour alternance.</a><br />
                                                    </li>
                                                    : ""}

                                                {process.env.REACT_APP_API_URL + this.props.ficheDePosteMedia ?
                                                    <li>

                                                        <a href={process.env.REACT_APP_API_URL + this.props.ficheDePosteMedia}>Fiche de poste Alternance</a><br />
                                                    </li>
                                                    : ""}
                                                <li>
                                                    <a>Signature</a>
                                                    <iframe src={this.props.signatureUrl} frameborder="0" style={{ border: 0, top: "0px", left: "0px", bottom: "0px", right: "0px", width: "100%", height: "100%" }} allowfullscreen></iframe><br />
                                                </li>




                                            </ol>
                                        </div>

                                    </Form.Group>

                                    {/* <div><AttestationDeSuiviEtFinDeFormationPDF /></div> */}
                                    <Form.Group>
                                        <PDFDownloadLink document={<CertificatDInscription
                                            nomDeLaFormation={this.props.nomDeLaFormation}
                                            nom={this.props.nom} prenom={this.props.prenom} dateDeNaissance={this.props.dateDeNaissance} dateDebutDeFormation={this.props.dateDebutDeFormation}
                                            dateFinDeFormation={this.props.dateFinDeFormation} nationalite={this.props.nationalite} fileName="CertificatDInscription.pdf"
                                        />}>

                                            {({ chargement }) => (chargement ? <Button>Chargement en cours...</Button> : <Button>Télécharger certificat d'inscription</Button>)}
                                        </PDFDownloadLink>
                                    </Form.Group>
                                    <Form.Group>

                                        <PDFDownloadLink document={<AttestationDeSuiviEtDeFormation nomDeLaFormation={this.props.nomDeLaFormation}
                                            nom={this.props.nom} prenom={this.props.prenom} fileName="AttestationDeSuiviDeFormation.pdf" />}>

                                            {({ chargement }) => (chargement ? <Button>Chargement en cours...</Button> : <Button>Télécharger Attestation de Suivi</Button>)}
                                        </PDFDownloadLink>
                                    </Form.Group>

                                    <Form.Group>
                                        <PDFDownloadLink document={<AttestationDeSuiviEtDeFinDeFormation nomDeLaFormation={this.props.nomDeLaFormation}
                                            nom={this.props.nom} prenom={this.props.prenom} sex={this.props.sex} fileName="AttestationDeFinDeFormation.pdf" />}>

                                            {({ chargement }) => (chargement ? <Button>Chargement en cours...</Button> : <Button>Télécharger attestation de suivi et de fin de formation</Button>)}
                                        </PDFDownloadLink>

                                        <NotificationContainer />
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.props.onHide}>Fermer</Button>
                    </Modal.Footer>
                </Modal>
            </div >

        );
    }
}
