import React, { image, useEffect } from 'react';
import { Document, Page, Text, View, StyleSheet, Image, pdf } from '@react-pdf/renderer';
import PictureLogoISMI from "../../images/PictureLogoISMI.png"
import signature from "../../images/SignatureISMI.png"
import ReactPDF from '@react-pdf/renderer';
import { PDFDownloadLink } from '@react-pdf/renderer';

// Create styles


const styles = StyleSheet.create({
    page: {
        //flexDirection: 'row',
        //backgroundColor: '#E4E4E4',
        // paddingTop: 35,
        // paddingBottom: 65,
        padding: 25,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    text: {
        textAlign: "justify",

        textAlign: "center",
        fontSize: "12pt"
    },
    textBold: {
        textAlign: "justify",
        fontWeight: "bold",
        textAlign: "center",
        fontSize: "12pt"
    },
    header: {
        textAlign: "center",
    },
    image: {
        width: "30%",
    },
    right: {
        marginTop: "30px",
        textAlign: "right",
        fontSize: "12px"

    },
    center: {

    },
    left: {
        textAlign: "left",
        marginTop: "20px",
        marginBottom: "20px",
        fontSize: "12px"

    },
    interlignes: {
        lineHeight: "0.5em",
    },
    footer: {
        textAlign: "center",
        fontSize: "10px",
        marginTop: "90px"
    },
    signature: {
        marginTop: "50px",
        marginBottom: "50px",
        alignItems: "center"
    }
});







export const CertificatDInscription = (props) => {

    return (
        <Document>
            <Page size="A4"
                style={styles.page}
            >
                <View >
                    <Image
                        style={styles.image}
                        src={PictureLogoISMI}
                    />
                </View>
                <View style={styles.right}>

                    <Text style={{ color: "red" }}>CERTIFICAT D'INSCRIPTION DÉFINITIVE</Text>
                </View>
                <View style={
                    styles.left}>
                    <Text>

                        N° d’enregistrement auprès du préfet IDF :</Text><br />
                    <Text style={{ paddingLeft: "50px" }}>
                        11 75 44 681 75
                    </Text>
                </View>
                <View><Text style={{
                    color: "black",
                    fontFamily: "Calibri" | "sansSerif",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    textDecoration: "none",
                    fontSize: "11pt",
                    margin: "0pt", paddingTop: "8pt", textIndent: "0pt", textAlign: "left"
                }}>
                </Text><br />
                </View>
                <View style={{ margin: "20px" }}>
                    {/* <Text style={{ paddingLeft: "168pt", textIndent: "0pt", textAlign: "left" }}>

                    </Text> */}
                    <Text style={{ paddingTop: "1pt", paddingleft: "74pt", textIndent: "0pt", textAlign: "center" }}>
                        {props.nomDeLaFormation}
                    </Text>
                </View>
                <View>
                    <Text style={{
                        color: "black",
                        fontFamily: "Calibri" | "sansSerif",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        textDecoration: "none",
                        fontSize: "11pt",
                        margin: "0pt", paddingTop: "1pt", textIndent: "0pt", textAlign: "left"
                    }}>

                    </Text>
                    <br />
                    <Text style={{ marginLeft: "20px", textAlign: "justify", fontSize: "12px", marginTop: "15px" }}>

                        Je soussignée, Madame Audrey DUONG, Directrice de l’Institut de Stratégie et de Management International, établissement d’enseignement supérieur privé, certifie que :
                    </Text>

                </View>
                <View>
                    <Text data-list-text="●" style={{
                        color: "black",
                        fontFamily: "Calibri" | "sansSerif",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        textDecoration: "none",
                        fontSize: "11pt",
                        margin: "0pt", paddingTop: "10pt", paddingLeft: "122pt", textIndent: "-10pt", textAlign: "left"
                    }}>

                        Nom de l&#39;étudiant (e ) : {props.nom}
                    </Text>


                    <Text data-list-text="●" style={{
                        color: "black",
                        fontFamily: "Calibri" | "sansSerif",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        textDecoration: "none",
                        fontSize: "11pt",
                        margin: "0pt", paddingLeft: "122pt", textIndent: "-10pt", textAlign: "left"
                    }}>

                        Prénom de l’étudiant (e ) : {props.prenom}

                    </Text>
                    <Text data-list-text="●" className="s2" style={{
                        color: "black",
                        fontFamily: "Calibri" | "sansSerif",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        textDecoration: "none",
                        fontSize: "11pt",
                        margin: "0pt", paddingLeft: "122pt", textIndent: "-10pt", textAlign: "left"
                    }}>

                        Né(e) le : {props.dateDeNaissance}
                    </Text>
                    <Text data-list-text="●" className="s2" style={{
                        color: "black",
                        fontFamily: "Calibri" | "sansSerif",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        textDecoration: "none",
                        fontSize: "11pt",
                        margin: "0pt", paddingLeft: "122pt", textIndent: "-10pt", textAlign: "left"
                    }}>
                        De nationalité : {props.nationalite}
                    </Text>
                    <Text style={{
                        color: "black",
                        fontFamily: "Calibri" | "sansSerif",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        textDecoration: "none",
                        fontSize: "11pt",
                        margin: "0pt", paddingTop: "11pt", textIndent: "0pt", textAlign: "left"
                    }}>

                    </Text><br />
                    <View style={{ paddingLeft: "53pt", textIndent: "0pt", lineHeight: "115%", textAlign: "justify" }}>

                    </View>
                    <View style={{ marginLeft: "20px", textAlign: "justify", fontSize: "12px", marginTop: "15px" }}>

                        <Text className="p">

                            A été accepté (e ) et est bien inscrit (e) au sein de notre institut pour la formation au {props.nomDeLaFormation}

                        </Text>
                        <Text className="h4">


                        </Text>
                        <Text className="p">
                            qui se déroule sous l&#39;autorité du Ministère de l’Education Nationale enregistré au RNCP. .

                        </Text>
                    </View>

                    <Text data-list-text="●" className="s2" style={{
                        color: "black",
                        fontFamily: "Calibri" | "sansSerif",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        textDecoration: "none",
                        fontSize: "11pt",
                        margin: "0pt", paddingTop: "10pt", paddingLeft: "122pt", textIndent: "-10pt", textAlign: "left"
                    }}>
                        Date de rentrée de formation:  {props.dateDebutDeFormation}
                    </Text>
                    <Text data-list-text="●" className="s2" style={{
                        color: "black",
                        fontFamily: "Calibri" | "sansSerif",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        textDecoration: "none",
                        fontSize: "11pt",
                        margin: "0pt", paddingLeft: "122pt", textIndent: "-10pt", textAlign: "left"
                    }}>
                        Date de fin de formation :     {props.dateFinDeFormation}
                    </Text>
                    <Text data-list-text="●" className="s2" style={{
                        color: "black",
                        fontFamily: "Calibri" | "sansSerif",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        textDecoration: "none",
                        fontSize: "11pt",
                        margin: "0pt", paddingLeft: "122pt", textIndent: "-10pt", textAlign: "left"
                    }}>
                        Durée de la formation :       1100 heures
                    </Text>
                    <Text data-list-text="●" className="s2" style={{
                        color: "black",
                        fontFamily: "Calibri" | "sansSerif",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        textDecoration: "none",
                        fontSize: "11pt",
                        margin: "0pt", paddingLeft: "122pt", textIndent: "-10pt", textAlign: "left"
                    }}>
                        Montant versé non remboursable : 375 euros
                    </Text>

                </View >
                <View>
                    <Text style={{
                        color: "black",
                        fontFamily: "Calibri" | "sansSerif",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        textDecoration: "none",
                        fontSize: "11pt",
                        margin: "0pt", paddingTop: "11pt", textIndent: "0pt", textAlign: "left"
                    }}>
                    </Text><br />
                    <Text style={{ marginLeft: "20px", textAlign: "justify", fontSize: "12px", marginTop: "15px" }}>

                        Compte tenu des diverses contraintes administratives (attente de visa, ...), l&#39;étudiant(e) dispose d&#39;une dérogation de rentrée tardive de 60 jours maximum après la date de rentrée pour se présenter en formation.
                    </Text>
                    <br />


                </View>
                <View style={styles.right}>
                    <Text >
                        Fait pour valoir ce que de droit,</Text><br /> <Text > à Paris,
                            le {new Intl.DateTimeFormat('fr-fr').format(Date.now())}
                    </Text> <br />

                    <Text style={{ textIndent: "0pt", textAlign: "left" }}>

                    </Text><br />
                </View>
                <View>
                    <Text style={styles.right} >
                        Audrey DUONG, Directrice
                    </Text>
                </View>
                <View style={styles.footer}>
                    <Text className="s4" >
                        © Institut de Stratégie et de Management International
                    </Text>
                    <Text className="s4"
                    >
                        Accueil sans RDV de 15h à 18h à ISMI, Tour Essor, 19è étage, Route des petits ponts, 75 019 Paris
                    </Text><br />
                    <Text> Adresse postale (courrier uniquement) : ISMI, 18 rue des Envierges, 75020 Paris
                    </Text><br />
                    <Text
                    >
                        Tél: 01 71 20 45 88, Site internet :
                        http://www.ismi.fr, E mail :
                        contact@ismi.fr
                    </Text>
                </View>
            </Page >
        </Document >
    )
}

