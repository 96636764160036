import React from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

export const NavBar = () => {

    const navigate = useNavigate();

    const handleLogout = () => {
        window.localStorage.removeItem("accessToken")
        navigate("/login")

    }
    const isAdmin = window.localStorage.getItem("isAdmin")

    //console.log(isAdmin)
    return (
        <div style={{ justifyContent: "space-between", justifyItems: "flex-end " }}>
            <div>

                <Link to="/dashboard">Candidatures</Link>
            </div>
            <div>

                <Link to="/inscrits">Etudiants</Link>
            </div>
            {/* {isAdmin ? (

                <div>
                    <Link to="/utilisateurs">Utilisateurs</Link>
                </div>
            ) : ""} */}
            <div>

                <Button onClick={handleLogout}>Se déconnecter</Button>
            </div>
            <div></div>
        </div>
    )
}