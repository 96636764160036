import { Button } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";

export const Navigation = () => {

    return (
        <div>
            <div className="collapse" id="navbarToggleExternalContent">

                <ul className="nav nav-tabs card-header-tabs">
                    <li className="nav-item">
                        <a className="nav-link disabled" href="https://www.ismi.fr/#section-efd0885-o">L'institut</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link disabled" href="https://www.ismi.fr/#section-79513e3-o">Compétences</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link disabled" href="https://www.ismi.fr/#section-1746d92-o">Pourquoi l'ISMI ?</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link disabled" href="https://www.ismi.fr/les-formations">Les formations</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link disabled" href="https://www.ismi.fr/#section-b098798-o">Témoignages</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link disabled" href="https://www.ismi.fr/#section-456207a-o">Contact</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link " href="https://inscription.ismi.fr">S'inscrire</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link disabled" href="https://www.elearning.ismi.fr">ISMI Campus en ligne</a>
                    </li>
                </ul>
            </div>
            <nav className="navbar navbar-dark bg-dark">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
            </nav>

        </div>
    );
}