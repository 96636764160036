import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    lng: "fr",
    fallbackLng: "fr",
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: {
            translation: {
                title: "Register",
                label: "Select another language!",
                home: "Home",
            },
        },
        es: {
            translation: {
                title: "Inscripción",
                label: "Selecciona otro lenguaje!",
                home: "Inicio",
            },
        }
    }
});

export default i18n;