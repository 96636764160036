function Completion(props) {
  return (
    <div id="header" className="col-md-6 offset-md-3">
      <h1>Merci pour votre inscription! 🎉<br />
        Nous vous recontactons dans les 72h</h1>
    </div>
  );
}

export default Completion;
