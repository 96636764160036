
import React, { Component } from 'react';
//import { AddPost } from '../popups/AddPost';
import { ProfilCandidat } from '../components/Popups/ProfilCandidat';
import { Button, ButtonToolbar, Table } from 'react-bootstrap';
import axios from 'axios';
//import { Navigation } from '../components/Navigation';
import { Redirect } from "react-router-dom";
import { NavBar } from '../components/NavBar';



export class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = { client: [], addModalShow: false, editModalShow: false, UtilisateurUsername: false };
    }

    // token = JSON.parse(
    //     JSON.parse(localStorage.getItem("persist:root"))
    // );


    refreshList() {


        fetch(process.env.REACT_APP_API_URL, {
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST,PATCH,OPTIONS, GET',
                authorization: "Bearer " + localStorage.getItem("accessToken")
            }
        })
            .then(response => {
                console.log(response);
                if (response.status === 200) {
                    return response.json()
                } else {
                    window.location.href = '/login';
                }
                console.log(response.status);
                throw response;
            })
            .then(data => {
                console.log(data);
                this.setState({ client: data });
            })
            .catch(error => {
                console.error(error.message)
            });



    }

    componentDidMount() {
        this.refreshList();
    }

    componentDidUpdate() {
        this.refreshList();
    }




    render() {
        const { client, clientid,
            cniMedia, lettreDeMotivationMedia, cvMedia, diplomeMedia,
            specialiteDiplome, nonPresenterDiplomePourquoi, autreObjectif
            , attentesEtMotivations, motivation, depuisCombienDeTempsInteressesParLaFormation,
            qualitesPourEtreUnBonProfessionnel, coordonneesReferentAlternance,
            besoinsSpecifiquesHandicap, besoinsCreationActivite, autresInformationsComplementaires,
            signatureUrl, nom, prenom, email, adresse, complementAdresse, numero, codePostal, ville, unUn, unDeux,
            unTrois, deuxUn, deuxTrois, deuxQuatre, troisUn, quatreD, quatreBisA, quatreBisC, cinqA, sixA, sixB,
            sixC, inscrit, cerfaMedia } = this.state;
        let addModalClose = () => this.setState({ addModalShow: false });
        let editModalClose = () => this.setState({ editModalShow: false });

        return (
            <div>
                <nav id="nav-wrap">

                    <NavBar />

                    {/* <Navigation /> */}
                </nav>  <div style={{ marginTop: 60, justifyContent: 'center' }}>

                    <h3>Candidatures</h3>
                    <div className="mt-5 d-flex justify-content-right">
                        <ButtonToolbar>
                            {/* <Button
                                variant='primary'
                                onClick={() => this.setState({ addModalShow: true })}>
                                Valider les candidatures
                            </Button> */}
                            {/* <AddPost show={this.state.addModalShow}
                                onHide={addModalClose} /> */}
                        </ButtonToolbar>
                    </div>
                    <div className="mt-5 d-flex justify-content-left">
                        <Table className="mt-4" striped bordered hover size="sm">
                            <thead>
                                <tr className='m-2'>
                                    <th>Nom</th>
                                    <th>Prénom</th>
                                    <th>Adresse Email</th>
                                    <th>N° de téléphone</th>
                                    <th>Créneau de disponibilité</th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* {console.log(coordonnees.nom)} */}
                                {client.map(client =>
                                    client.inscrit ? ""
                                        : <tr key={client.clientid} >
                                            <td>{client.nom}</td>
                                            <td>{client.prenom}</td>
                                            <td>{client.email}</td>
                                            <td>{"+" + client.numero}</td>
                                            <td>{client.creneauHoraire}</td>
                                            <td>
                                                <ButtonToolbar>
                                                    <Button
                                                        className="mr-2"
                                                        variant="info"
                                                        onClick={() => this.setState({
                                                            editModalShow: true, clientid: client._id, cvMedia: client.cvMedia, cniMedia: client.cniMedia,
                                                            diplomeMedia: client.diplomeMedia,
                                                            lettreDeMotivationMedia: client.lettreDeMotivationMedia,
                                                            nom: client.nom, prenom: client.prenom, numero: client.numero, email: client.email, adresse: client.adresse, complementAdresse: client.complementAdresse, codePostal: client.codePostal, ville: client.ville, creneauHoraire: client.creneauHoraire,
                                                            nonPresenterDiplomePourquoi: client.nonPresenterDiplomePourquoi,
                                                            autreObjectif: client.autreObjectif, attentesEtMotivations: client.attentesEtMotivations,
                                                            motivation: client.motivation, depuisCombienDeTempsInteressesParLaFormation: client.depuisCombienDeTempsInteressesParLaFormation,
                                                            qualitesPourEtreUnBonProfessionnel: client.qualitesPourEtreUnBonProfessionnel
                                                            , besoinsSpecifiquesHandicap: client.besoinsSpecifiquesHandicap,
                                                            coordonneesReferentAlternance: client.coordonneesReferentAlternance,
                                                            besoinsCreationActivite: client.besoinsCreationActivite, autresInformationsComplementaires: client.autresInformationsComplementaires,
                                                            specialiteDiplome: client.specialiteDiplome,
                                                            signatureUrl: client.signatureUrl, inscrit: client.inscrit,
                                                            nationalite: client.nationalite, dateDebutFormation: client.dateDebutFormation,
                                                            dateFinDeFormation: client.dateFinDeFormation, unUn: client.unUn, unDeux: client.unDeux,
                                                            unTrois: client.unTrois, deuxUn: client.deuxUn, deuxTrois: client.deuxTrois,
                                                            deuxQuatre: client.deuxQuatre, troisUn: client.troisUn, quatreD: client.quatreD,
                                                            quatreBisA: client.quatreBisA, quatreBisC: client.quatreBisC,
                                                            cinqA: client.cinqA, sixA: client.sixA, sixB: client.sixB,
                                                            sixC: client.sixC, cerfaMedia: client.cerfaMedia
                                                        })}>Voir les infos
                                                    </Button>



                                                    {/* <EditPost */}
                                                    <ProfilCandidat
                                                        show={this.state.editModalShow}
                                                        onHide={editModalClose}
                                                        clientid={clientid}
                                                        specialiteDiplome={specialiteDiplome}
                                                        autreObjectif={autreObjectif}
                                                        motivation={motivation}
                                                        nonPresenterDiplomePourquoi={nonPresenterDiplomePourquoi}
                                                        attentesEtMotivations={attentesEtMotivations}
                                                        depuisCombienDeTempsInteressesParLaFormation={depuisCombienDeTempsInteressesParLaFormation}
                                                        qualitesPourEtreUnBonProfessionnel={qualitesPourEtreUnBonProfessionnel}
                                                        coordonneesReferentAlternance={coordonneesReferentAlternance}
                                                        besoinsSpecifiquesHandicap={besoinsSpecifiquesHandicap}
                                                        besoinsCreationActivite={besoinsCreationActivite}
                                                        autresInformationsComplementaires={autresInformationsComplementaires}
                                                        signatureUrl={signatureUrl}
                                                        cniMedia={cniMedia}
                                                        cvMedia={cvMedia}
                                                        diplomeMedia={diplomeMedia}
                                                        lettreDeMotivationMedia={lettreDeMotivationMedia}
                                                        cerfaMedia={cerfaMedia}
                                                        nom={nom}
                                                        prenom={prenom}
                                                        adresse={adresse}
                                                        complementAdresse={complementAdresse}
                                                        numero={numero}
                                                        codePostal={codePostal}
                                                        ville={ville}
                                                        email={email}
                                                        inscrit={inscrit}
                                                        unUn={unUn}
                                                        unDeux={unDeux}
                                                        unTrois={unTrois}
                                                        deuxUn={deuxUn}
                                                        deuxTrois={deuxTrois}
                                                        deuxQuatre={deuxQuatre}
                                                        troisUn={troisUn}
                                                        quatreD={quatreD}
                                                        quatreBisA={quatreBisA}
                                                        quatreBisC={quatreBisC}
                                                        cinqA={cinqA}
                                                        sixA={sixA}
                                                        sixB={sixB}
                                                        sixC={sixC}
                                                    />
                                                </ButtonToolbar>
                                            </td>
                                        </tr>)}
                            </tbody>
                        </Table>


                    </div>
                </div>
            </div >)
    }

}
