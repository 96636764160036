import React from 'react'
import { Navigation } from '../components/Navigation';

export const ConditionsGeneralesDUtilisation = () => {

    return (
        <div>

            <div>
                <Navigation />
            </div>
            <div>

                <div style={{ margin: 50 }}>

                    <h1>
                        Conditions générales d'utilisation
                    </h1>
                </div>

                En vigueur au 05/09/2024

                <p>

                    Les présentes conditions générales d'utilisation (dites « CGU ») ont pour objet l'encadrement
                    juridique des modalités de mise à disposition du site et des services par Marketies  et de
                    définir les conditions d’accès et d’utilisation des services par « l'Utilisateur ».
                </p>
                <p>

                    Les présentes CGU sont accessibles sur le site à la rubrique «CGU».
                </p>

                <h2>

                    Article 1 : Les mentions légales
                </h2>

                <p>

                    L'édition du site inscription.ismi.fr est assurée par la Société SARL Marketies
                    au capital de 100 euros, immatriculée au RCS de Paris sous le numéro 509250254,
                    dont le siège social est situé au 18 rue des Envierges
                    Numéro de téléphone +33 1 71 20 45 88
                    Adresse e-mail : contact@ismi.fr.
                    Le Directeur de la publication est : Audrey DUONG
                    Numéro de TVA intracommunautaire : FR95509250254
                </p>

                <p>

                    L'hébergeur du site inscription.ismi.fr est la société Hostinger,
                    dont le siège social est situé au 61 Lordou Vironos Street, 6023 Larnaca, Chypre,
                    joignable par le moyen suivant : https://www.hostinger.fr/contact.
                </p>

                <h2>
                    ARTICLE 2 : Accès au site
                </h2>
                <p>

                    Le site inscription.ismi.fr  permet à l'Utilisateur un accès gratuit aux services suivants :
                    Le site internet propose les services suivants :
                    - Inscription aux formations en ligne
                    - Paiement des frais d'inscription
                    - Gestion des candidatures
                    Le site est accessible gratuitement en tout lieu à tout Utilisateur
                    ayant un accès à Internet.
                    Tous les frais supportés par l'Utilisateur pour accéder au service   (matériel informatique,
                    logiciels, connexion Internet, etc.) sont à sa charge.

                </p>

                <h2>
                    ARTICLE 3 : Collecte des données
                </h2>
                <p>

                    Le site assure à l'utilisateur une collecte et un traitement d'informations
                    personnelles dans le respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978
                    relative à l'informatique, aux fichiers et aux libertés.
                </p>

                <p>

                    En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur dispose
                    d'un droit d'accès, de rectification, de suppression et d'opposition de ses données personnelles.
                    L'Utilisateur exerce ce droit par mail à l'adresse email contact@ismi.fr﻿.
                </p>

                <h2>
                    ARTICLE 4 : Propriété intellectuelle
                </h2>
                <p>

                    Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son…) font l'objet
                    d'une protection par le Code de la propriété intellectuelle et plus particulièrement
                    par le droit d'auteur.
                </p>
                <p>

                    La marque ISMI est une marque déposée par Marketies.Toute représentation et/ou
                    reproduction et/ou exploitation partielle ou totale de cette marque, de quelque nature que ce soit,
                    est totalement prohibée.
                </p>
                <p>

                    L'Utilisateur doit solliciter l'autorisation préalable du site pour toute reproduction, publication,
                    copie des différents contenus. Il s'engage à une utilisation des contenus du site dans un cadre
                    strictement privé, toute utilisation à des fins commerciales et publicitaires est strictement
                    interdite.
                </p>
                <p>

                    Toute représentation totale ou partielle de ce site par quelque procédé que ce soit,
                    sans l’autorisation expresse de l’exploitant du site Internet constituerait une contrefaçon
                    sanctionnée par l’article L 335-2 et suivants du Code de la propriété intellectuelle.
                </p>
                <p>

                    Il est rappelé conformément à l’article L122-5 du Code de propriété intellectuelle que
                    l’Utilisateur qui reproduit, copie ou publie le contenu protégé doit citer l’auteur et sa source.
                </p>
                <h2>

                    ARTICLE 5 : Responsabilité
                </h2>
                <p>

                    Les sources des informations diffusées sur le site inscription.ismi.fr sont réputées fiables
                    mais le site ne garantit pas qu’il soit exempt de défauts, d’erreurs ou d’omissions.
                </p>
                <p>

                    Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle.
                    Malgré des mises à jour régulières, le site inscription.ismi.fr ne peut être tenu responsable
                    de la modification des dispositions administratives et juridiques survenant après la publication.
                    De même, le site ne peut être tenu responsable de l’utilisation et de l’interprétation de
                    l’information contenue dans ce site.
                    Le site inscription.ismi.fr ne peut être tenu pour responsable d’éventuels virus qui pourraient
                    infecter l’ordinateur ou tout matériel informatique de l’Internaute, suite à une utilisation,
                    à l’accès, ou au téléchargement provenant de ce site.
                </p>
                <p>

                    La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible
                    et insurmontable d'un tiers.
                </p>

                <h2>
                    ARTICLE 6 : Liens hypertextes
                </h2>
                <p>

                    Des liens hypertextes peuvent être présents sur le site. L'utilisateur est informé qu’en cliquant
                    sur ces liens, il sortira du site inscription.ismi.fr. Ce dernier n’a pas de contrôle sur
                    les pages web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas,
                    être responsable de leur contenu.
                </p>

                <h2>
                    ARTICLE 7 : Cookies
                </h2>
                <p>

                    L’utilisateur est informé que lors de ses visites sur le site, un cookie peut s’installer
                    automatiquement sur son logiciel de navigation.
                </p>
                <p>

                    Les cookies sont de petits fichiers stockés temporairement sur le disque dur de l’ordinateur
                    de l'utilisateur par votre navigateur et qui sont nécessaires à l’utilisation du site
                    inscription.ismi.fr. Les cookies ne contiennent pas d’information personnelle et ne peuvent pas être utilisés pour identifier quelqu’un. Un cookie contient un identifiant unique, généré aléatoirement et donc anonyme. Certains cookies expirent à la fin de la visite de l'utilisateur, d’autres restent.
                </p>
                <p>

                    L’information contenue dans les cookies est utilisée pour améliorer le site inscription.ismi.fr.
                </p>
                <p>

                    En naviguant sur le site, l'utilisateur les accepte.
                    L’Utilisateur pourra désactiver ces cookies par l’intermédiaire des paramètres figurant
                    au sein de son logiciel de navigation.
                </p>
                <h2>

                    ARTICLE 8 : Droit applicable et juridiction compétente
                </h2>
                <p>

                    La législation française s'applique au présent contrat. En cas d'absence de résolution
                    amiable d'un litige né entre les parties, les tribunaux français seront seuls compétents
                    pour en connaître.
                    Pour toute question relative à l’application des présentes CGU, vous pouvez joindre l’éditeur
                    aux coordonnées inscrites à l’ARTICLE 1.
                </p>
                <p>

                    CGU réalisées sur http://legalplace.fr/
                </p>

            </div>
        </div >
    );
}
