import axios from 'axios';
import React, { Component } from 'react';
import { Modal, Button, Row, Col, Form, Image } from 'react-bootstrap';


export class AddUser extends Component {

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        // this.handleFileSelected = this.handleFileSelected.bind(this);
    }




    handleSubmit(event) {
        event.preventDefault();
        axios.post(process.env.REACT_APP_API + 'users', {
            headers: {

                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST,PATCH,OPTIONS, GET, DELETE',
                authorization: "Bearer " + localStorage.getItem("accessToken")
            },
            body: JSON.stringify({

                username: event.target.username.value,
                password: event.target.password.value
            })
        })
            .then(response =>
                response.json()
            )
            .then((result) => {
                alert(result);
            })
            .catch(error => {
                console.error(error.message)
            });
    }

    // handleFileSelected(event) {
    //     event.preventDefault();
    //     this.photofilename = event.target.files[0].name;
    //     const formData = new FormData();

    //     formData.append(
    //         "myFile",
    //         event.target.files[0],
    //         event.target.files[0].name
    //     );

    //     fetch(process.env.REACT_APP_API + 'Posts/SaveFile', {
    //         method: 'POST',
    //         body: formData
    //     })
    //         .then(response => response.json())
    //         .then((result) => {
    //             this.imagesrc = process.env.REACT_APP_PHOTOPATH + result
    //             console.log({ result })
    //         },
    //             (error) => {
    //                 alert('Failed');
    //             })

    // }


    render() {

        return (
            <div className="container">
                <Modal
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header >
                        <Modal.Title id="contained-modal-title-vcenter">
                            Ajouter un Post
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={6}>
                                <Form onSubmit={this.handleSubmit}>
                                    <Form.Group controlId="Post">
                                        <Form.Label>Création d'un post</Form.Label>


                                        <Form.Control
                                            type="text"
                                            name="username"
                                            required
                                            placeholder="Pénom et nom de l'utilisateur"
                                        />
                                        <Form.Control
                                            type="text"
                                            name="password"
                                            required
                                            placeholder="Mot de passe de l'utilisateur"
                                        />

                                        <Button variant="primary" type="submit">
                                            Ajouter un Utilisateur
                                        </Button>
                                    </Form.Group>
                                </Form>
                            </Col>

                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.props.onHide}>Fermer</Button>
                    </Modal.Footer>
                </Modal>
            </div>

        );
    }
}


//
//headers:{
//   'Accept':'application/json',
//   'Content-Type':'application/json',
//   "Access-Control-Allow-Origin": "*"
//},